<template>
    <div>
        <x-header>
            <img slot="headerImage" src="../../../assets/qingguanfuwu/banner.jpg" alt="">
            <h1 slot="headerTitle">{{$store.state.navShow == true ?'清关服务—国家级清关行，自有清关团队优质高效' : 'Customs Clearance Service—National Customs Clearance Bank, Self-owned Customs Clearance Team with High Quality and Efficiency'}}</h1>
        </x-header>
        <div class="qingguanfuwu">
            <p>{{$store.state.navShow == true ? 'MG集团打造了国家级清关行，在俄罗斯和白俄罗斯的所有关键地点（远东和南部联邦区、俄中部和西南部）都设置了专业清关团队，可用最短的时间，在俄罗斯主要港口进行海关清关，最大限度节省交货时间成本。':'MG Group has built a national-level customs clearance bank, and has set up professional customs clearance teams in all key locations in Russia and Belarus (Far East and Southern Federal District, Central and Southwest Russia) to use the shortest time to clear customs at major ports in Russia, Minimize delivery time cost.'}}</p>
        <!-- 业务范围 -->
            <div class="yewu_range">
                <p>{{$store.state.navShow == true ?'业务范围':'Business scope'}}</p>
                <div class="yewu_range_kuang">
                    <div class="yewu_range_items" v-for="(item,index) in $store.state.navShow == true ? rangeItemsArr :rangeItemsArrE" :key="index" data-aos="flip-right" :data-aos-delay="Number(index * 100)">
                        <img :src="item.img" alt="">
                        <p>{{item.title}}</p>
                    </div>
                </div>

            </div>
        </div>
      <!-- 提供服务 -->
        <div class="provideServices">
          <div class="provideServices_neirong">
            <p>{{$store.state.navShow == true ? '提供服务' :'Provide services'}}</p>
            <div class="neirong_kuang" data-aos="fade-up">
              <div class="neirong_items" v-for="(item,index) in $store.state.navShow == true ?  provideServiceArr : provideServiceArrE" :key="index">
                <img :src="item.provideImg" alt="">
                <p>{{item.provideTitle}}</p>
              </div>

            </div>

          </div>
        <!-- 底图 -->
          <div class="provideServices_diImg">
            <img src="../../../assets/qingguanfuwu/bottomBanner.png" alt="">
          </div>
        </div>
    </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {
      // 业务范围 数组
      rangeItemsArr: [
        {
          img: require('../../../assets/qingguanfuwu/MinskCustoms.png'),
          title: '莫斯科机场海关'
        },
        {
          img: require('../../../assets/qingguanfuwu/MoscowRailway.png'),
          title: '莫斯科铁路站点海关'
        },
        {
          img: require('../../../assets/qingguanfuwu/MinskCustoms.png'),
          title: '明斯克海关'
        },
        {
          img: require('../../../assets/qingguanfuwu/VladivostokPort.png'),
          title: '海参崴港口海关'
        }
      ],
      rangeItemsArrE: [
        {
          img: require('../../../assets/qingguanfuwu/MinskCustoms.png'),
          title: 'Moscow Airport Customs'
        },
        {
          img: require('../../../assets/qingguanfuwu/MoscowRailway.png'),
          title: 'Moscow railway station customs'
        },
        {
          img: require('../../../assets/qingguanfuwu/MinskCustoms.png'),
          title: 'Minsk customs'
        },
        {
          img: require('../../../assets/qingguanfuwu/VladivostokPort.png'),
          title: 'Vladivostok port customs and other cities'
        }
      ],
      // 提供服务 数组
      provideServiceArr: [
        {
          provideImg: require('../../../assets/qingguanfuwu/serve1.png'),
          provideTitle: '经验丰富的境外清关操作团队'
        },
        {
          provideImg: require('../../../assets/qingguanfuwu/serve2.png'),
          provideTitle: '精细化的报关清关服务流程'
        },
        {
          provideImg: require('../../../assets/qingguanfuwu/serve3.png'),
          provideTitle: '国家级清关行专属海关护送组'
        },
        {
          provideImg: require('../../../assets/qingguanfuwu/serve4.png'),
          provideTitle: '实时掌握货物运踪及通关状态'
        }
      ],
      provideServiceArrE: [
        {
          provideImg: require('../../../assets/qingguanfuwu/serve1.png'),
          provideTitle: 'Experienced overseas customs clearance operation team'
        },
        {
          provideImg: require('../../../assets/qingguanfuwu/serve2.png'),
          provideTitle: 'Refined customs declaration and clearance service process'
        },
        {
          provideImg: require('../../../assets/qingguanfuwu/serve3.png'),
          provideTitle: 'State-level exclusive customs escort team'
        },
        {
          provideImg: require('../../../assets/qingguanfuwu/serve4.png'),
          provideTitle: 'Real time tracking of goods and customs clearance status'
        }
      ]

    }
  }
}
</script>
<style lang="less" scoped>
    .qingguanfuwu{
        width: var(--width1200px);
        margin: 112px auto 105px;
        // background: orange;
        & > p{
            text-indent: 32px;
            text-align: left;
            line-height: 32px;
        }
        .yewu_range{
            width: 100%;
            display: flex;

            flex-direction: column;
            & > p{
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2E67B1;
                text-align: left;
                margin: 75px 0 28px 0;
            }
            .yewu_range_kuang{
                display: flex;
                justify-content: space-between;
                & > .yewu_range_items{
                    width: 285px;
                    height: 400px;
                    background: #16283F;
                    position: relative;
                    & > p{
                        width: 100%;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                        position: absolute;
                        bottom: 22px;
                        left:0;
                        text-align: center;
                    }
                }
            }

        }

    }

    // 提供服务
    .provideServices{
      width: 100%;
      height: 430px;
      position: relative;
      // 提供服务内容
      .provideServices_neirong{
        width: var(--width1200px);
        // height: 170px;
        // display: flex;
        // justify-content: space-between;
        margin: 0 auto;
        & > p{
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2E67B1;
          text-align: left;
          margin-bottom: 30px;
        }
        .neirong_kuang{
          display: flex;
          justify-content: space-between;
            .neirong_items{
              width: 282px;
              height: 170px;
              background: #FFFFFF;
              box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              // transition: all .25s;
              cursor: pointer;
              & > p{
                margin-top: 25px;
                color: #666666;
                font-family: Microsoft YaHei;
              }

            }
        }

      }
      // 底图
      .provideServices_diImg{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        & > img{
            vertical-align: middle;
        }
      }
    }
</style>
